import * as config from '../../../config.js'
var url = config.API_ROOT
var build_url = config.API_ROOT_DRONE

export async function getBuildDetail(data) {
  console.log('in api', data)
  const response = await fetch(url + 'api/v1/project/tPartyConfig/drone/rebuild?projectDetailId=' + data);
  return await response.json();
}

export async function getBuildList(owner, repo, token) {
  console.log('owner', owner)
  console.log('repo', repo)
  console.log('token', token)
  const response = await fetch(build_url + 'api/repos/'+ repo +'/'+ owner +'/'+ 'builds', {
    headers: {Authorization: 'Bearer' +' '+ token}
  });
  return await response.json();
}


export async function reBuildProject(branch, name, nameSpace, token) {
  const response = await fetch(build_url + 'api/repos/' + nameSpace +'/'+ name +'/'+'builds?branch=' + branch, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        'Authorization': 'Bearer' +' '+ token
    }
  })
  return await response.json();
}